import React from 'react'
import { Flex } from 'rebass'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import IconNotFound from '../assets/svg/notfound.inline.svg'

const NotFoundPage = props => {
  const intl = useIntl()
  return (
    <Layout {...props}>
      <Flex
        py={3}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={{ marginTop: 250, minHeight: 500 }}
      >
        <ResponsiveSvg />
        <p>{intl.formatMessage({ id: 'not_found' })}</p>
      </Flex>
    </Layout>
  )
}
const ResponsiveSvg = styled(IconNotFound)`
  height: 350px;
  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 300px;
  }
`
export default NotFoundPage
